import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { UserLoginModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";
import { AgenziaUtenza, AgenzieRegistrazioneItem } from "@/models/agenzie/agenzieRegistrazioneItem";
import { TextValueExtended } from "@/models/TextValueItem";
import { filterBy } from "@progress/kendo-data-query";
import { Comune, Provincia } from "@/models/istatModels";

@Component({
	methods: { ...mapActions(["loginUser"]) }
})
export default class registerPage extends baseContextComponent {
	reCaptchaClientKey: string = "";
	validationOccured: boolean = false;
	minDate: Date = new Date(1900,1,1);
	showRegistrazioneEseguitaMessage: boolean = false;
	get logoPath() {
		return `${this.$appConfig.baseUrl}logo.png`;
		// return `${process.env.BASE_URL}logo.png`;
		//return   `${process.env.VUE_APP_BASE_URL}/logo.png`;
	}
	//sedeLegaleDiversaDaOperativa: boolean = false;
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";
	item: AgenzieRegistrazioneItem = new AgenzieRegistrazioneItem();
	isLoading: boolean = false;
	recuperata: boolean = false;
	user: UserLoginModel = new UserLoginModel();
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	emailRules = [
		(v: string) => {
			return !!v || "Campo obbligatorio";
		},
		(v: string | null) => v == null || /.+@.+/.test(v) || "Formato non valido"
	];
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];
	uppercaseformatter(value: string) {
		return value != null ? value.toUpperCase() : value;
	}
	regioni: TextValueExtended[] = [];
	ruoli: TextValueExtended[] = [];
	ddlFilterRegioni: any = null;

	associazioneAgenzia: any = null;

	get dropdownSourceRegioni() {
		var self = this;
		const data = self.regioni.slice();
		if (self.ddlFilterRegioni == null) return data;
		var filtered = filterBy(data, this.ddlFilterRegioni);
		return filtered;
	}
	onFilterSelectChangeRegioni(event: any) {
		this.ddlFilterRegioni = event.filter;
	}
	province: Provincia[] = [];
	ddlFilterProvince: any = null;
	get dropdownSourceProvince() {
		var self = this;
		const data = self.province.slice();
		if (self.ddlFilterProvince == null) return data;
		var filtered = filterBy(data, this.ddlFilterProvince);
		return filtered;
	}
	onFilterSelectChangeProvince(event: any) {
		this.ddlFilterProvince = event.filter;
	}

	onChengeProvincia(event) {
		var self = this;
		self.item.citta = null;
		var codiceProvincia = null;
		if (event != null && event.value != null) {
			var val = event.value as Provincia;
			codiceProvincia = ((val ?? {}) as Provincia).codiceProvincia;
		}
		if ((codiceProvincia ?? "") == null) {
			self.comuni = [];
		} else {
			api.getDatiDropDownAny<Comune[]>(`${api.endpoint.DROPDOWN.ANAGRAFICA_COMUNI}?provincia=${codiceProvincia}`).then(res => {
				self.comuni = res;
			});
		}
	}
	comuni: Comune[] = [];
	ddlFilterComuni: any = null;
	get dropdownSourceComuni() {
		var self = this;
		const data = self.comuni.slice();
		if (self.ddlFilterComuni == null) return data;
		var filtered = filterBy(data, this.ddlFilterComuni);
		return filtered;
	}
	onFilterSelectChangeComuni(event: any) {
		this.ddlFilterComuni = event.filter;
	}
	get isComuniDdlDisabled(): boolean {
		return this.comuni == null || this.comuni.length == 0;
	}
	onChengeProvinciaLeg(event) {
		var self = this;
		self.item.cittaLeg = null;
		var codiceProvincia = null;
		if (event != null && event.value != null) {
			var val = event.value as Provincia;
			codiceProvincia = ((val ?? {}) as Provincia).codiceProvincia;
		}
		if ((codiceProvincia ?? "") == null) {
			self.comuniLeg = [];
		} else {
			api.getDatiDropDownAny<Comune[]>(`${api.endpoint.DROPDOWN.ANAGRAFICA_COMUNI}?provincia=${codiceProvincia}`).then(res => {
				self.comuniLeg = res;
			});
		}
	}
	comuniLeg: Comune[] = [];
	ddlFilterComuniLeg: any = null;
	get dropdownSourceComuniLeg() {
		var self = this;
		const data = self.comuniLeg.slice();
		if (self.ddlFilterComuniLeg == null) return data;
		var filtered = filterBy(data, this.ddlFilterComuniLeg);
		return filtered;
	}
	onFilterSelectChangeComuniLeg(event: any) {
		this.ddlFilterComuniLeg = event.filter;
	}
	get isComuniLegDdlDisabled(): boolean {
		return this.comuniLeg == null || this.comuniLeg.length == 0;
	}
	created() {
		var self = this;

		var associazioneID = this.$route && this.$route.query && this.$route.query.id
				? Number.parseInt(( this.$route.query as any).id) : null;

		if( associazioneID && associazioneID > 0){
			api.get(api.endpoint.AGENZIE.ASSOCIAZIONI + "/" + associazioneID).then(res => {
				debugger
				if( res ) {
					self.associazioneAgenzia = res;
					self.item.associatoNetwork = res.network
				} 
			});
		}
		
		self.item = new AgenzieRegistrazioneItem();
		self.item.userTitolare.push(new AgenziaUtenza());
		//self.item.userContabilita.push(new AgenziaUtenza());
		self.item.userOperativi.push(new AgenziaUtenza());
		api.getDatiDropDown(api.endpoint.DROPDOWN.REGIONI).then(res => {
			self.regioni = res as TextValueExtended[];
		});
		api.getDatiDropDownAny<Provincia[]>(api.endpoint.DROPDOWN.ANAGRAFICA_PROVINCE).then(res => {
			self.province = res;
		});
		api.getDatiDropDown(api.endpoint.DROPDOWN.RUOLI_WEB).then(res => {
			self.ruoli = res as TextValueExtended[];
		});
		this.reCaptchaClientKey = process.env.VUE_APP_RECAPTCHA_CLIENT_KEY;
	}
	checkForm(): boolean {
		var self = this;

		//Campi obbligatori
		if (
			this.denominazioneState === false ||
			this.ragioneSociale === false ||
			this.email === false ||
			this.pec === false ||
			this.pIva === false ||
			this.cf === false ||
			this.registroImprese === false ||
			this.autorizzazione === false ||
			this.autorizzazioneRegione === false ||
			this.autorizzazioneData === false ||
			//this.associatoNetwork === false ||
			this.address === false ||
			this.cap === false ||
			this.tel === false ||
			this.provincia === false ||
			this.citta === false ||
			this.addressLeg === false ||
			this.capLeg === false ||
			this.provLeg === false ||
			this.cittaLeg === false ||
			this.nome === false ||
			this.cognome === false ||
			this.userName === false ||
			this.emailTitolare === false ||
			this.cellulare === false ||
			this.dataNascita === false ||
			this.fileVisuraCamerale === false ||
			this.fileCartaIdentita === false ||
			this.fileCodiceFiscale === false ||
			this.fileAutorizzazione === false ||
			this.privacyCheck === false
		) {
			self.$bvToast.toast(self.$i18n.t("errore.campoObbligatori").toString(), {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});

			return false;
		}

		//Username uguali
		var existsSameUserName = false;
		for (var i = 0; i < 5; i++) {
			var userNameRoot = i == 0 ? this.item.userName : this.item[`userName${i}`];
			if (userNameRoot) {
				for (var j = 0; j < 5; j++) {
					if (i == j) continue;
					var userNameChild = j == 0 ? this.item.userName : this.item[`userName${j}`];

					if (userNameChild && userNameRoot == userNameChild) existsSameUserName = true;
				}

				if (this.item.contUserName && this.item.contUserName == userNameRoot) existsSameUserName = true;
			}
		}
		if (existsSameUserName) {
			self.$bvToast.toast(self.$i18n.t("errore.userNameUguali").toString(), {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});

			return false;
		}

		//recaptcha
		if (!this.item.tokenReCaptcha || this.item.tokenReCaptcha.length <= 0) {
			self.$bvToast.toast(self.$i18n.t("errore.reCaptchaObbligatorio").toString(), {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});

			return false;
		}

		return true;
	}
	addNewUser() {
		if (this.item.userContabilita && this.item.userContabilita.length > 3) {
			this.$bvToast.toast(this.$i18n.t("generico.toManyUsers").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
			return;
		}

		this.item.userContabilita.push(new AgenziaUtenza());
	}

	removeUser(index: number) {
		if (this.item.userContabilita && this.item.userContabilita.length >= index) {
			this.item.userContabilita.splice(index, 1);
		}

		this.item[`userNome${index + 2}`] = "";
		this.item[`userName${index + 2}`] = "";
		this.item[`userCognome${index + 2}`] = "";
		this.item[`userEmail${index + 2}`] = "";
	}

	onInputFileVisuraCameraleValue(value: File) {
		var fileValueField = `visuraCameraleFile`;

		Vue.set(this.item, fileValueField, value);
	}

	onInputFileCartaIdentitaValue(value: File) {
		var fileValueField = `cartaIdentitaFile`;

		Vue.set(this.item, fileValueField, value);
	}

	onInputFileCodiceFiscaleValue(value: File) {
		var fileValueField = `codiceFiscaleFile`;

		Vue.set(this.item, fileValueField, value);
	}

	onInputFileAutorizzazioneValue(value: File) {
		var fileValueField = `autorizzazioneFile`;

		Vue.set(this.item, fileValueField, value);
	}

	onCaptchaVerified(token: any) {
		this.item.tokenReCaptcha = token;
	}

	onCaptchaExpired() {
		this.item.tokenReCaptcha = "";
		(this.$refs.recaptcha as any).reset();
	}

	saveRegistration() {
		//Effettuare controlli
		var self = this;
		this.validationOccured = true;

		if (!this.checkForm()) {
			return;
		}

		this.item.caricatoDocumentoAutorizzazione = true;
		this.item.caricatoDocumentoCodiceFiscale = true;
		this.item.caricatoDocumentoIdentita = true;
		this.item.caricatoDocumentoVisura = true;

		if (this.item.ruoliTitolareForm && this.item.ruoliTitolareForm.value /* && this.item.ruoliTitolare.length > 0*/) {
			// this.item.ruoliTitolare = this.item.ruoliTitolare.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliTitolare = [this.item.ruoliTitolareForm.value];
		}
		else {
			this.item.ruoliTitolare = [];
		}

		if (this.item.ruoliUser1Form && this.item.ruoliUser1Form.value /*&& this.item.ruoliUser1.length > 0*/) {
			// this.item.ruoliUser1 = this.item.ruoliUser1.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliUser1 = [this.item.ruoliUser1Form.value];
		}
		else {
			this.item.ruoliUser1 = [];
		}

		if (this.item.ruoliUser2Form && this.item.ruoliUser2Form.value /*&& this.item.ruoliUser1.length > 0*/) {
			// this.item.ruoliUser1 = this.item.ruoliUser1.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliUser2 = [this.item.ruoliUser2Form.value];
		}
		else {
			this.item.ruoliUser2 = [];
		}

		if (this.item.ruoliUser3Form && this.item.ruoliUser3Form.value /*&& this.item.ruoliUser1.length > 0*/) {
			// this.item.ruoliUser1 = this.item.ruoliUser1.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliUser3 = [this.item.ruoliUser3Form.value];
		}
		else {
			this.item.ruoliUser3 = [];
		}

		if (this.item.ruoliUser4Form && this.item.ruoliUser4Form.value /*&& this.item.ruoliUser1.length > 0*/) {
			// this.item.ruoliUser1 = this.item.ruoliUser1.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliUser4 = [this.item.ruoliUser4Form.value];
		}
		else {
			this.item.ruoliUser4 = [];
		}

		if (this.item.ruoliUser5Form && this.item.ruoliUser5Form.value /*&& this.item.ruoliUser1.length > 0*/) {
			// this.item.ruoliUser1 = this.item.ruoliUser1.map(function(item) {
			// 	return item.value;
			// })

			this.item.ruoliUser5 = [this.item.ruoliUser5Form.value];
		}
		else {
			this.item.ruoliUser5 = [];
		}

		if( this.associazioneAgenzia && this.associazioneAgenzia.itemID)
			this.item.idAssociazione = this.associazioneAgenzia.itemID;

		api.registrazioneNuovaAgenzia(this.item)
			.then(res => {
				var title = self.$i18n.t("msg.salvataggioRiuscito").toString();
				var message = self.$i18n.tc("generico.azioneeseguita").toString();

				self.$bvToast.toast(message, {
					variant: "success",
					title: title,
					solid: true
				});

				self.showRegistrazioneEseguitaMessage = true;

				setTimeout(() => {
					self.$router.push({ name: "login" });
				}, 10000);
			})
			.catch(err => {
				console.log(err);
			});
	}

	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}

	get denominazioneState() {
		if (!this.validationOccured) return null;

		if (this.item.denominazione && this.item.denominazione.length > 0) return true;

		return false;
	}

	get ragioneSociale() {
		if (!this.validationOccured) return null;

		if (this.item.ragioneSociale && this.item.ragioneSociale.length > 0) return true;

		return false;
	}

	get email() {
		if (!this.validationOccured) return null;

		if (this.item.email && this.item.email.length > 0) return true;

		return false;
	}

	get pec() {
		if (!this.validationOccured) return null;

		if (this.item.pec && this.item.pec.length > 0) return true;

		return false;
	}

	get pIva() {
		if (!this.validationOccured) return null;

		if (this.item.pIva && this.item.pIva.length > 0) return true;

		return false;
	}

	get cf() {
		if (!this.validationOccured) return null;

		if (this.item.cf && this.item.cf.length > 0) return true;

		return false;
	}

	get registroImprese() {
		if (!this.validationOccured) return null;

		if (this.item.registroImprese && this.item.registroImprese.length > 0) return true;

		return false;
	}

	get autorizzazione() {
		if (!this.validationOccured) return null;

		if (this.item.autorizzazione && this.item.autorizzazione.length > 0) return true;

		return false;
	}

	get autorizzazioneRegione() {
		if (!this.validationOccured) return null;

		if (this.item.autorizzazioneRegione && this.item.autorizzazioneRegione.code) return true;

		return false;
	}

	get autorizzazioneData() {
		if (!this.validationOccured) return null;

		if (this.item.autorizzazioneData) return true;

		return false;
	}

	get associatoNetwork() {
		if (!this.validationOccured) return null;

		if (this.item.associatoNetwork && this.item.associatoNetwork.length > 0) return true;

		return false;
	}

	get address() {
		if (!this.validationOccured) return null;

		if (this.item.address && this.item.address.length > 0) return true;

		return false;
	}

	get cap() {
		if (!this.validationOccured) return null;

		if (this.item.cap && this.item.cap.length > 0) return true;

		return false;
	}

	get tel() {
		if (!this.validationOccured) return null;

		if (this.item.tel && this.item.tel.length > 0) return true;

		return false;
	}

	get provincia() {
		if (!this.validationOccured) return null;

		if (this.item.provincia && this.item.provincia.siglaAutomobilistica) return true;

		return false;
	}

	get citta() {
		if (!this.validationOccured || this.provincia === false) return null;

		if (this.item.citta && this.item.citta.codiceComune) return true;

		return false;
	}

	get addressLeg() {
		if (!this.validationOccured || !this.item.showSedeLegale) return null;

		if (this.item.addressLeg && this.item.addressLeg.length > 0) return true;

		return false;
	}

	get capLeg() {
		if (!this.validationOccured || !this.item.showSedeLegale) return null;

		if (this.item.capLeg && this.item.capLeg.length > 0) return true;

		return false;
	}

	get provLeg() {
		if (!this.validationOccured || !this.item.showSedeLegale) return null;

		if (this.item.provLeg && this.item.provLeg.siglaAutomobilistica) return true;

		return false;
	}

	get cittaLeg() {
		if (!this.validationOccured || this.provLeg === false || !this.item.showSedeLegale) return null;

		if (this.item.cittaLeg && this.item.cittaLeg.codiceComune) return true;

		return false;
	}

	get nome() {
		if (!this.validationOccured) return null;

		if (this.item.nome && this.item.nome.length > 0) return true;

		return false;
	}

	get cognome() {
		if (!this.validationOccured) return null;

		if (this.item.cognome && this.item.cognome.length > 0) return true;

		return false;
	}

	get userName() {
		if (!this.validationOccured) return null;

		if (this.item.userName && this.item.userName.length > 0) return true;

		return false;
	}

	get emailTitolare() {
		if (!this.validationOccured) return null;

		if (this.item.emailTitolare && this.item.emailTitolare.length > 0) return true;

		return false;
	}

	get cellulare() {
		if (!this.validationOccured) return null;

		if (this.item.cellulare && this.item.cellulare.length > 0) return true;

		return false;
	}

	get dataNascita() {
		if (!this.validationOccured) return null;

		if (this.item.data) return true;

		return false;
	}

	get fileVisuraCamerale() {
		if (!this.validationOccured) return null;

		if (this.item.visuraCameraleFile) return true;

		return false;
	}

	get fileCartaIdentita() {
		if (!this.validationOccured) return null;

		if (this.item.cartaIdentitaFile) return true;

		return false;
	}

	get fileCodiceFiscale() {
		if (!this.validationOccured) return null;

		if (this.item.codiceFiscaleFile) return true;

		return false;
	}

	get fileAutorizzazione() {
		if (!this.validationOccured) return null;

		if (this.item.autorizzazioneFile) return true;

		return false;
	}

	get privacyCheck() {
		if (!this.validationOccured) return null;

		if (this.item.privacy) return true;

		return false;
	}

	// isValidEmail(email) {
	// 	return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
	// }

	// get emailCheck() {
	// 	if (!this.validationOccured) return true;
	// 	if(this.email === false) return true;

	// 	if (this.isValidEmail(this.item.email)) return true;

	// 	return false;
	// }

	onGoLogin() {
		this.$router.push({ name: "login" });
	}
}
